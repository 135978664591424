<template>
  <v-container fluid class="px-4">
    <v-row>
      <v-col>
        <div class="d-flex align-center justify-space-between">
          <div class="d-flex align-center">
            <v-btn
              x-small
              class="white--text mt-2"
              color="warning"
              elevation="0"
              @click="
                () =>
                  this.$router.push({
                    path: '/abstract-campaign',
                  })
              "
            >
              <v-icon small> mdi-arrow-left </v-icon>
            </v-btn>
            <h2 class="mt-2 ml-4 dark--text d-flex">
              {{ singleAbstractCampaign.name }}
            </h2>
          </div>
          <v-btn
            small
            v-if="!isCampaignCompareActive"
            color="blue white--text ml-4 mt-3 p-2"
            dark
            elevation="0"
            @click.stop="setCampaignCompareActive"
          >
            <span class="pr-1">Comparar</span>
            <v-icon> mdi-swap-horizontal-bold </v-icon>
          </v-btn>
          <v-btn
            small
            elevation="0"
            v-if="isCampaignCompareActive"
            color="red white--text ml-4 mt-3 p-2"
            dark
            @click.stop="unSetCampaignCompareActive"
          >
            <span class="pr-1">Cancelar</span>
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
        <v-row>
          <v-col align="end">
            <v-data-table
              class="mt-2"
              dense
              :headers="
                [
                  { name: 'Versión', value: 'version' },
                  { name: 'Nombre de Versión', value: 'campaignVersionName' },
                  { name: 'Fecha de inicio', value: 'startDate' },
                  { name: 'Fecha de finalización', value: 'endDate' },
                ].map((i) => {
                  return { text: i.name, value: i.value };
                })
              "
              :items="processedCampaigns"
              :show-select="isCampaignCompareActive"
              item-key="id"
              multi-sort
              hide-default-footer
              v-model="selected"
              @click:row="handleRowClick"
              ><template v-slot:[`header.data-table-select`]></template
            ></v-data-table>
            <div
              class="d-flex justify-space-between pa-2"
              v-if="isCampaignCompareActive"
            >
              <span
                v-if="selected.length < 2"
                class="small grey--text font-italic font-weight-light text-disabled text-caption"
                >*Selecciona al menos dos elementos</span
              >
              <span
                v-else-if="selected.length > 2"
                class="small red--text font-italic font-weight-light text-caption"
                >*Solo puedes comparar dos elementos a la vez</span
              >
              <span v-else></span>
              <v-btn
                small
                elevation="0"
                color="blue white--text ml-4 my-3 p-2"
                :class="{ grey: selected.length != 2 }"
                @click.stop="redirectToCompareCampaignVersions"
              >
                <span class="pr-1">Ir a Comparar</span>
                <v-icon> mdi-swap-horizontal-bold </v-icon>
              </v-btn>
            </div>
          </v-col></v-row
        ></v-col
      ></v-row
    ></v-container
  >
</template>

<script>
// import Table from "../components/Table";
import { mapGetters } from "vuex";
import useSelectable from "../mixins/useSelectables";
import useSaveNewEngagement from "../mixins/useSaveNewEngagement";

export default {
  //
  name: "Campaigns",
  mixins: [useSelectable, useSaveNewEngagement],
  computed: {
    ...mapGetters(["campaigns", "singleAbstractCampaign", "establishments"]),
    campaignTableKeys() {
      return this.campaigns[0] ? Object.keys(this.campaigns[0]) : [];
    },
    processedCampaigns() {
      return this.campaigns.map((i) => {
        return {
          id: i.id,
          campaignVersionName: i.campaignVersionName,
          version: i.version,
          startDate: new Date(this.singleAbstractCampaign.startDate)
            .toLocaleString("en-US", {
              timeZone: "America/Argentina/Buenos_Aires",
            })
            .split(",")[0],
          endDate: new Date(this.singleAbstractCampaign.endDate)
            .toLocaleString("en-US", {
              timeZone: "America/Argentina/Buenos_Aires",
            })
            .split(",")[0],
        };
      });
    },
  },

  async mounted() {
    this.$store.dispatch("getSingleAbstractCampaign", this.$route.params.id);
    this.$store.dispatch("getCampaigns", this.$route.params.id);
    await this.$store.dispatch("getAbstractCampaigns");
    document.title = this.singleAbstractCampaign?.name;
  },
  methods: {
    redirectToCompareCampaignVersions() {
      if (this.selected.length == 2) {
        let id1 = this.selected[0]?.id;
        let id2 = this.selected[1]?.id;
        this.$router.push({
          name: "CampaignVersionCompare",
          params: {
            abstractCampaignId: this.singleAbstractCampaign?.id,
            id1: id1,
            id2: id2,
          },
        });
      }
    },
    unSetCampaignCompareActive() {
      this.isCampaignCompareActive = false;
      this.selected = [];
    },
    setCampaignCompareActive() {
      this.isCampaignCompareActive = true;
      this.selected = [];
    },
    handleRowClick(e) {
      if (!this.isCampaignCompareActive) {
        this.$router.push({
          path: `/abstract-campaign/${this.$route.params.id}/campaign/${e.id}`,
        });
      }
    },
  },
  data() {
    return {
      selected: [],
      isCampaignCompareActive: false,
    };
  },
};
</script>
,
